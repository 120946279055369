  <!-- Site footer -->
  <footer class="site-footer">
        <div fxLayout="row" fxLayoutAlign="space-around center" >
            <ul class="social-icons">
            <li><a class="twitch" href="https://www.twitch.tv/avgtim/"><fa-icon [icon]="faTwitch"></fa-icon></a></li>
            <li><a class="youtube" href="https://www.youtube.com/channel/UCOjcMjU1vmTSpwkQTgA855Q"><fa-icon [icon]="faYoutube"></fa-icon></a></li>
            <li><a class="discord" href="https://discord.gg/ZjgGZba"><fa-icon [icon]="faDiscord"></fa-icon></a></li>
            <li><a class="instagram" href="https://www.instagram.com/avgtim/"><fa-icon [icon]="faInstagram"></fa-icon></a></li>
            <li><a class="twitter" href="https://twitter.com/AvgTim"><fa-icon [icon]="faTwitter"></fa-icon></a></li> 
            </ul>
        </div>
      <hr>
      <div class="row">
          <p class="copyright-text">Copyright &copy; 2021 All Rights Reserved by 
       <a href="#">AvgTim</a>.
          </p>
      </div>
</footer>