import { Component, OnInit } from '@angular/core';
import { faTwitter, faTwitch, faInstagram, faYoutube, faDiscord } from '@fortawesome/free-brands-svg-icons'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  faTwitch = faTwitch;
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  faYoutube = faYoutube;
  faDiscord = faDiscord

  constructor() { }

  ngOnInit(): void {
  }

}
