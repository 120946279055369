<div id='header'>    
    <h1>So, you're interested in streaming?</h1>
    <p>I wanted a space to talk about streaming! Whether you are interseted in trying yourself, or just interested in how the 
        sausage is made, I wanted to give you my tips and tricks, as well as essential tools (and dream tools) for the trade!</p>
</div>
<div id='intro'>    
    <p>I got into streaming on April 11th 2020. Quarintine had just started, and it seemed like the perfect time. But the truth is, I've wanted
        to stream on Twitch for the past 4 years. My biggest regret is not starting sooner. You don't need fancy equipment to start streaming,
        and as you will see below, you can do it on a budget! Initally streamin was just an excuse to play more video games, but over the last XXX days,
        it has become so much more. I've met some really incredible people, and started to build a great community of friendly, like-minded folks. Feel free to
        join us over at our <a href='https://discord.gg/ZjgGZba'></a>discord!
    </p>
</div>
<div id='buildSection'>
    <h2>Builds</h2>
    <p>Looking for advice on what equipment to get? Unfortunatly the answer isn't that cut and dry, 
        I've put together three different lists at different price points to help you make your decision. 
        Now obviously there are always other options out there, but I try to stike a good balence between price and quality.
    Alternatively, you could always just go full <a href='https://www.elgato.com/en'>Elgato</a> (the apple of streaming).... </p>
    <div id='budgetBuild'></div>
    <div id='bangBuild'></div>
    <div id='highendBuild'></div>
</div>
<div id='peopleSection'>
    <h2>How to Stream</h2>
    <p>The first question people usually ask a steamer is what equipment to get. Although that is an important factor, I've seen succesful streams 
        with people using an old camera to capture game play and a $5 mic to capture their audio. At the end of the day, the most important thing 
    is <b>you</b>. Below are some of my tips on actually streaming:</p>
    <ul>
        <li>Be yourself </li>
        <li class='indent'>If you are energetic and swear alot, you will attract people who like that! 
            Are you quiet? Thats okay too! You will attract people who want a more chill stream. At the end of the day, as long as you are being your authentic self, 
        you will attract like minded individuals.</li>
        <li>God Gamer or Entertainer?</li>
        <li class='indent'>There are two ways to attract people to your stream. If you are in the top 5% of a certain game, you will attract people as they come to watch
            your god tier gameplay. Generally, I think it's faster to grow this way but *generally* the viewer cieling is lower here. The other type of streamer is the 
        entertainer. This is much harder to grow, as most streamer probably fall under this category and the competition is larger, but the ceiling is much higher, as you attract
     a larger audience! Think GodTier: PangaeaPange (Mario Maker God - 266K YT Subs, 1,200 Avg Viewers) Vs. Ludwig (Entertainer - 1.39M YT Subs, 22,000 Avg Viewers)</li>
    </ul>
</div>
