<mat-toolbar class='mat-elevation-z4 toolbar'>
  <button mat-button class='home'>
    <span [routerLink]="['/home']">AvgTim</span>
  </button>
  

    <button mat-button [matMenuTriggerFor]="menu" aria-label="Socials" class='navbarItem'>
      <span mat-button>Socials</span>
    </button>
    <mat-menu #menu="matMenu" xPosition="after" class="menu">
      <a class="twitch" mat-menu-item href="https://www.twitch.tv/avgtim" target="_blank"><fa-icon [icon]="faTwitch"></fa-icon><span>  Twitch</span></a>
      <a class="youtube" mat-menu-item href="https://www.youtube.com/channel/UCOjcMjU1vmTSpwkQTgA855Q" target="_blank"><fa-icon [icon]="faYoutube"></fa-icon>  YouTube</a>
      <a class="discord" mat-menu-item href="https://discord.gg/ZjgGZba" target="_blank"><fa-icon [icon]="faDiscord"></fa-icon>  Discord</a>
      <a class="instagram" mat-menu-item href="https://www.instagram.com/avgtim/" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon>  Instagram</a>
      <a class="twitter" mat-menu-item href="https://twitter.com/AvgTim" target="_blank"><fa-icon [icon]="faTwitter"></fa-icon>  Twitter</a>
    </mat-menu>
    <a mat-button [routerLink]="['/streaming']">Streaming Guide</a>
    <a mat-button href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank">Test - Don't Click</a>
  </mat-toolbar>
