import { Component, OnInit } from '@angular/core';

declare const Twitch: any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  

  constructor() { }



  ngOnInit(): void {
    new Twitch.Embed('twitch-embed', {
      channel:'avgtim',
      muted:'true',
      height:"100%",
      width:"100%"
    });
  }

}
